const initialState = {
    response: ""
}

const participant = (state = initialState, action) => {
    switch (action.type) {  
        default:
            return state;
    }

};
export default participant;