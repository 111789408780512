
export const setMessage = (alertMessage) => {
  
  return {
    type: "SET_ALERT_MESSAGE",
    alertMessage
  }
}

export const removeMessage = () => {
  return {
    type: "SET_MESSAGE_TO_ZERO"
  }
}