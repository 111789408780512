import wrong from "../../../../src/assets/SometingwentWrong.png";
import mobile from "../../../../src/assets/SometingwentWrong_mobile.png";
const AlertMessage = (props) => {

  let errorMessage = "";

  if (props.alertMessage.hasOwnProperty("message") && 
    props.alertMessage.message?.length > 0) {
      errorMessage = props.alertMessage.message;
  } else {
    errorMessage = "Please Contact to support. Thank You"
  }

  return (
    <>
    <div>
      {errorMessage && (
        <>
          <div id="terminateDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
            <img src={wrong} alt="Error" style={{ width: "100%", height: "100%" }} />
          </div>
          <div id="rejectDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
            <img src={mobile} alt="terminate" style={{ width: "100%", height: "100%" }} />
          </div>
        </>)}
    </div>
  </>
  )
}

export default AlertMessage;