import  Loader  from "react-js-loader";

const MyLoader = (props) => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Loader type="spinner-default"  bgColor={"#BBB"} title={"Please Wait..."} color={'#BBB'} size={100} />
        </div>
    )
}

export default MyLoader;