import { startSpinner, endSpinner } from "../ui/elements/loader/Action";
import {updateParticipantFromClient} from "../api/apiService";
import { setMessage } from "../ui/elements/alert/Action";



export const updateParticipantFromClientAction = (allQueryParams, landingURL) => {
	return dispatch => {
		dispatch(startSpinner());
		updateParticipantFromClient(allQueryParams, landingURL).then((result) => {
			debugger
			dispatch(endSpinner());
			if (result.success) {
				// Set Cookie ID Here
				
				if(result.result.responseType == "MESSAGE"){
					dispatch(setMessage( {success : false, message : result.result.message}));
				} else if(result.result.responseType == "REDIRECT"){
					window.location.href = result.result.redirectURL;
				} else {
					dispatch(setMessage( {success : false, message : "Ooops ! There is some issue with link."}));
				}
			} else {
				dispatch(setMessage( {success : false, message : result.message}));
			}
		});
	}
}