let queryString = window.location.search;
let badUrlHitting = false;

if (queryString && queryString.includes("TSP")) {
  badUrlHitting = true;
}

let API_URL = 'https://participantapi.trayistats.com/api/';

//if(badUrlHitting){
    API_URL = process.env.REACT_APP_API_URL;
//}



exports.BASE_URL = API_URL;