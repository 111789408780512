import {combineReducers} from 'redux';
import participant from './participant';
import spinner from '../ui/elements/loader/Reducer';
import alert from '../ui/elements/alert/Reducer';


const rootReducer = combineReducers({
    participant,
    spinner,
    alert
});
export default rootReducer;
