export const startSpinner = () => {
  return {
    type: "SET_SPINNER_STARTED"
  }
}

export const endSpinner = () => {
  return {
    type: "SET_SPINNER_COMPLETED"
  }
}