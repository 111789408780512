const initialState = {
  alertMessage: ""
}

const alert = (state = initialState, action) => {
  
  switch (action.type) {

    case "SET_ALERT_MESSAGE":
      return {
        ...state,
        alertMessage: action.alertMessage,
      }

    case "SET_MESSAGE_TO_ZERO":
      return {
        ...state,
        alertMessage: "",
      }

    default:
      return state;
  }

};
export default alert;