import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './ui/component/home';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MyLoader from './ui/elements/loader/loader';
import AlertMessage from './ui/elements/alert/index';
import './App.css';

function App(props) {

  const [content, setContent] = useState();


  useEffect(() => {
    let loader = "";
    if(props.loading){
      loader = <MyLoader/>
    }

    let content = "";
    if(props.alertMessage && props.alertMessage.success == false){
      content = <AlertMessage  alertMessage={props.alertMessage} />
    } else {
      content = <div className="App">
                    <Router>
                      <Routes>
                        <Route  element={<Home/>}  exact path="/:key?" />
                      </Routes>
                    </Router>
                    {loader}
                 </div> 
    }

    setContent(content);

  }, [props.loading, props.alertMessage]);


  return (
    <div>{content}</div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.spinner.loading,
    alertMessage: state.alert.alertMessage
  };
}
export default connect(mapStateToProps)(App);
